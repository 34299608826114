import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <div className="container text-base overflow-hidden max-w-6xl">
          <h1 className="font-bold text-4xl md:text-5xl border-b border-white pb-5 mb-5">Contact</h1>
          <p className="mt-5 mb-10">We're excited to hear from you! Whether you're looking to launch a new merchandise line, or build a brand from the ground up, we're here to help. If you have questions, ideas, or want to collaborate, don't hesitate to reach out. Let's create something great together!</p>
          <p className="my-5 flex items-center justify-start"><i className="bx bx-envelope-open text-xl text-white mr-3" /><span className=""><a href="mailto:connect@flatblackism.com" target="_blank">connect@flatblackism.com</a></span></p>
          <p className="my-5 flex items-center justify-start"><a href="https://maps.app.goo.gl/s56Ndc3C4dFMmNUo8" target="_blank" className="flex gap-3 items-center justify-start"><i className="bx bx-map-pin text-xl text-white" /><span className="">315 N Puente St, Suite D, Brea, CA 92821</span></a></p>
          <p className="my-5"><a href="https://instagram.com/flatblack.ism" target="_blank" className="flex gap-3 items-center justify-start"><i className="bx bxl-instagram text-xl text-white inline-block" /><span className="inline-block">@flatblack.ism</span></a></p>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="About" />

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
