import * as React from "react"
import Nav from "./nav.js"
import Footer from "./footer.js"

const Layout = ({ location = {}, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const pathname = location.pathname || "/";
  const isRootPath = pathname === rootPath;
  let header


  return (
    <div className="min-h-screen w-full absolute bg-mainBG bg-fixed bg-contain bg-repeat-y bg-center md:bg-cover md:bg-no-repeat text-zinc-100 font-outfit antialiased" data-is-root-path={isRootPath}>
      <div className="absolute min-h-screen inset-0 bg-black opacity-60"></div>
        <div className="relative z-10 min-h-screen">
        <div className="grid grid-cols-1 xl:grid-cols-10 gap-4">
          <div className="col-span-2">
            <Nav />
          </div>
          <div className="col-span-8">
            <div className="p-5 pt-28 md:py-32 xl:pr-10">
              <main>{children}</main>
            </div>
          </div>
        </div>
        <Footer />
        </div>
    </div>
  )
}

export default Layout
