import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';

const Footer = () => {
  const location = useLocation();
  const [isContactPage, setIsContactPage] = useState(false);

  useEffect(() => {
    if (location.pathname === '/contact' || location.pathname === '/contact/') {
      setIsContactPage(true);
    } else {
      setIsContactPage(false);
    }
  }, [location.pathname]); 

  return (
    <div className="grid grid-cols-1 xl:grid-cols-10 gap-4">
      <div className="col-span-2" />
      <div className="col-span-8">
        {!isContactPage && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 border-t border-b border-neutral-50 p-5 md:py-5 footerLinks">
            <div className="flex items-center justify-start">
              <i className="bx bx-envelope-open text-xl text-white mr-3"></i>
              <span><a href="mailto:connect@flatblackism.com" target="_blank" rel="noopener noreferrer">connect@flatblackism.com</a></span>
            </div>
            <div className="flex items-center justify-start">
              <i className="bx bx-map-pin text-xl text-white mr-3"></i>
              <span><a href="https://maps.app.goo.gl/s56Ndc3C4dFMmNUo8" target="_blank" rel="noopener noreferrer">315 N Puente St, Suite D, Brea, CA 92821</a></span>
            </div>
            <div>
              <ul>
                <li className="md:px-3">
                  <a href="https://instagram.com/flatblack.ism" target="_blank" rel="noopener noreferrer" className="flex gap-3 items-center justify-start">
                    <i className="bx bxl-instagram text-xl text-white inline-block" />
                    <span className="inline-block">@flatblack.ism</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div className="col-span-5 w-full p-5 md:py-4 text-sm">
          © {new Date().getFullYear()} <a href="https://www.flatblackism.com/" title="FLATBLACK">FLATBLACK</a>. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
